import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import { GrClose } from "react-icons/gr";
import { ApiErrorMessagesMapper } from "../../services/api-client";
import {
  initWidget,
  setAccessToken,
  setCallbackFunctions,
  toggleWidgetProcessing,
  toggleWidgetRender,
} from "../../actions/widget-actions";
import { resolveInstitution } from "../../shared/masamune-widget-utils";
import BlankWidgetScreen from "../shared/blank-widget-screen";
import ModalFlow from "./modal_flow";
import OldFlow from "./old_flow";

const customInstitutionFromState = (state) =>
  state.globalConfig.customInstitutionSelected;
const ibvSequenceFromState = (state) => state.globalConfig.ibvSequence;
const currVendorIdxFromState = (state) => state.globalConfig.currVendorIdx;
const shouldRenderFromState = (state) => state.globalConfig.shouldRender;

const selectedInstitution = createSelector(
  [currVendorIdxFromState, ibvSequenceFromState, customInstitutionFromState],
  (currVendorIdx, ibvSequence, customInstitution) => {
    if (!ibvSequence) {
      return null;
    }

    const provider = ibvSequence?.vendors[currVendorIdx];
    const vendorName = provider?.vendor_name;
    const customInstitutionSelected = customInstitution[vendorName];

    return resolveInstitution(
      customInstitutionSelected,
      ibvSequence,
      currVendorIdx
    );
  }
);

const selectedVendor = createSelector(
  currVendorIdxFromState,
  ibvSequenceFromState,
  (currVendorIdx, ibvSequence) => {
    if (!ibvSequence) {
      return null;
    }

    return ibvSequence?.vendors[currVendorIdx];
  }
);

const Index = (props) => {
  const {
    access_token,
    onError,
    onClose,
    onLoginFailure,
    onSuccess,
    showCloseButton
  } = props;
  const dispatch = useDispatch();
  const institution = useSelector(selectedInstitution);
  const vendor = useSelector(selectedVendor);
  const shouldRender = useSelector(shouldRenderFromState);
  const { widgetConfiguration } = useSelector((state) => state.globalConfig);
  const vendorName = vendor?.vendor_name;

  const closeWidget = () => {
    dispatch(toggleWidgetProcessing(false));
    dispatch(toggleWidgetRender());
    onClose({ message: 'User clicked the close button', closed: true });
    window.NinjaFetchWidget.unmount();
  };

  useEffect(() => {
    try {
      dispatch(setCallbackFunctions({ onSuccess, onError, onLoginFailure }));
      dispatch(setAccessToken(access_token));
      dispatch(initWidget({ props }));
    } catch (e) {
      onError(ApiErrorMessagesMapper(e));

      if (e.cause.statusCode === 401) {
        dispatch(toggleWidgetRender());
      }
    }
  }, []);

  if (!shouldRender) {
    return <BlankWidgetScreen />;
  }

  return (
    <div
      id="ninja-fetch-widget"
      className="flex justify-center flex-col w-full max-w-[400px] m-5"
    >
      {showCloseButton && (
        <button className="self-end mb-2" onClick={closeWidget}>
          <GrClose />
        </button>
      )}
      {!widgetConfiguration?.features?.bankSelection && (
        <OldFlow {...props} vendorName={vendorName} institution={institution} />
      )}
      {widgetConfiguration?.features?.bankSelection && (
        <ModalFlow
          {...props}
          vendorName={vendorName}
          institution={institution}
        />
      )}
    </div>
  );
};

export default Index;
